html {
  height: 100%;
}

body {
  margin: 0;
  /* font-family: -apple-system, 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #f9f9f9;
}

#root {
  height: 100%;
}

/* code {
  font-family: source-code-pro, 'Helvetica Neue', sans-serif;
} */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #8f8f8f;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #74b9ff;
}

::-webkit-scrollbar-thumb:active {
  background: #636e72;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #dedede;
}

/* ::-webkit-scrollbar-track:active {
  background: #333333;
} */

::-webkit-scrollbar-corner {
  background: transparent;
}

.ant-table-tbody tr:nth-child(2n) td {
  background-color: #fafafa;
}

.ant-table-header tr th {
  background-color: #f1f1f1 !important;
  border-right: 1px solid #ecebeb !important;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
  background: #ecebeb !important;
}